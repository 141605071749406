import { template as template_ed0566b1c24748d59793582a4c6c8f88 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_ed0566b1c24748d59793582a4c6c8f88(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
