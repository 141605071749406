import { template as template_de7363560f7840dab3965d5a1b55f5c4 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuCopyLinkButton = template_de7363560f7840dab3965d5a1b55f5c4(`
  <DButton
    class="post-action-menu__copy-link"
    ...attributes
    @action={{@buttonActions.copyLink}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.copy_action"}}
    @title="post.controls.copy_title"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuCopyLinkButton;
