import { template as template_f9d3bc19f74d4bc9959d0c6b0d4b5a9f } from "@ember/template-compiler";
const WelcomeHeader = template_f9d3bc19f74d4bc9959d0c6b0d4b5a9f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
