import { template as template_ef01c61bdaa6471c85c7e8332a9bb6ed } from "@ember/template-compiler";
const FKText = template_ef01c61bdaa6471c85c7e8332a9bb6ed(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
